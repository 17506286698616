@mixin transition($element: all) {
  transition: $element $time $ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@mixin transition-none() {
  transition: none !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@mixin placeholder($color) {
  &::-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
}
@function pxtorem($size:  16) {
  @return ($size /  16) + rem;
}

@mixin ul_reset {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: block;

    a {
      display: block;
    }
  }
}

@mixin bg_set($size: cover, $position: center center, $repeat: no-repeat) {
  background-size: $size;
  background-position: $position;
  background-repeat: $repeat;
}

@mixin lh($value, $lh: $lh) {
  line-height: ($value + $lh) * 1px;
  line-height: ($value + $lh) / $bs * 1rem;
}

@mixin fs($size: 16, $line: $size * 1.5) {
  font-size: ($size) + px;
  //line-height: ($line) + px;
  $calc-size: $size /  16;
  $calc-line: $line /  16;

  font-size: $calc-size + rem;
  //line-height: $calc-line + rem;
}

@function pxtorem($size:  16) {
  @return ($size /  16) + rem;
}

@mixin material-box {
  box-shadow: 3px 3px 79px rgba(0, 0, 0, 0.2);
  border: 1px solid #e1e1e1;
  background-color: $white;
}

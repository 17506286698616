.footer {
  padding: $offset 0;
  color: $white;
  background-color: $primary;
  border-bottom: 10px solid $dark;

  &__nav {
    ul {
      margin: 2rem 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: space-between;
      @media(max-width: $screen_mobile_max) {
        flex-direction: column;
        align-items: center;
      }
      > li {
        @media(max-width: $screen_mobile_max) {
          padding: $offset-sm;
        }
        > a {
          padding: $offset-sm;
        }
      }
    }
  }
  &__content {
    float: none;
    padding: $offset 0;
    border-top: 1px solid rgba($white, .3);
    @include fs(14);
      >img {
        max-width: 100%;
        margin: $offset-sm 0;
      }
      > p > a {
      text-decoration: underline;
      @include transition();
      &:hover {
        color: $dark;
        text-decoration: none;
      }
    }
  }
}



/*---------------------------------------*\
   banner
\*---------------------------------------*/

body.page-template-template-single,
body.single,
body.category{
  .bgmain {
    background-size: contain;
    background-position: right top;
    background-repeat: no-repeat;
  }
  .section_banner {
    &__text {
      color: $primary;
      h1 {
        margin-bottom: $offset-md;
        @include lh(40);
        small {
          @include fs(36);
          @include lh(20);
          span {
            color: $dark;
          }
        }
      }
      p {
        max-width: 600px;
      }
    }
  }
  .header #navigation ul li:first-child>a {
    border: none;
    color: $primary;
  }
  .section_portfolio {
    header {
      display: none;
    }
    .slick-center {
      border-radius: 0 0 20px 20px;
      border: 3px solid #c2d7de;
    }
  }
}
.section_banner {
  width: 100%;
  height: 65%;
  height: 65vh;
  min-height: 540px;
  @include bg_set();

  &__text {
      padding: 12rem 2rem 6rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      color: $white;
      z-index: 2;
    h1 {
      @include fh();
      @include fs(50);
      letter-spacing: 1px;
      margin: 0;
      @media(max-width: $screen_mobile_max) {
        @include fs(30);
      }
      }
  }
}

/*---------------------------------------*\
   About
\*---------------------------------------*/

.section_about {
  padding: $offset-xl 0;
  &__counts {
    small {

    }
    .divider {
      width: 117px;
      height: 4px;
      background-color: #a8a39f;
      opacity: 0.1;
      margin: $offset-xs 0;
    }
    h2 {
      color: $dark;
      @include fh();
      @include fs(30)
    }
  }
  &__text {
    @include fs(18);
    @include lh(24);
  }
}

/*---------------------------------------*\
   cta
\*---------------------------------------*/
.section_cta {
  display: flex;
  @media(max-width: $screen_tablet_max) {
    flex-direction: column;
  }

    &__side {
    flex-basis: 50%;
    @include bg_set();
    padding: $offset-lg 0;
    @media(max-width: $screen_tablet_max) {
      flex-direction: column;
      margin: 5px 10px;
    }
    &:first-of-type {
      margin-right: $offset-sm;
      text-align: right;
    }
    &:nth-last-child(1) {
      margin-left: $offset-sm;
      text-align: left;
    }
    a {
      font-weight: bold;
      padding: pxtorem(18) pxtorem(55);
      box-shadow: 0 0 25px 4px rgba(17, 17, 17, 0.1);
      background-color: $primary;
      color: $white;
      letter-spacing: 1px;
      @include transition();
      &:hover {
        background-color: lighten($primary, 1);
      }

    }
  }
}

/*---------------------------------------*\
   activities
\*---------------------------------------*/
.section_activities {
  padding: $offset-xl 0;
  @include bg_set();
  background-position: bottom;
  header {
    > h1 {
      margin-bottom: $offset-lg;
    }
  }
  &__card {
    box-shadow: 8px 11px 14px 2px rgba(22, 30, 33, 0.2);
    border-radius: 0 0 20px 20px;
    background-color: rgba($white, .9);
    border-top: 9px solid rgba(#c2d7de, .7);
    padding: pxtorem(55) pxtorem(42);
  }
  &__items {
    padding-top: $offset-xl;
  }
  &__item {
    margin: $offset 0;
    img {
      max-height: 56px;
      margin: $offset-sm 0;
    }
  }
}

/*---------------------------------------*\
   Portfolio
\*---------------------------------------*/

.section_portfolio {
  padding: $offset-md 0 $offset-xl;
  &__carousel {
    padding: $offset 0;
  }
  &__item {
    max-width: 297px;
    box-shadow: 8px 11px 44px 7px rgba(22, 30, 33, 0.04);
    border-radius: 0 0 20px 20px;
    background-color: $white;
    &__in {
      padding: 1rem;
      h2 {
        color: $dark;
        @include fs(18);
        @include fh();
      }
      span {
        display: block;
        @include fs(14);
        margin-bottom: 1rem;
        img {
          max-width: 100%;
          display: inline-block;
          margin-right: 5px;
          position: relative;
          bottom: 5px;
        }
      }
      p {
        @include fs(14);
      }
      > div.text-right {
        padding: 10px 0;
        a {
          font-weight: 500;
          color: $dark;
          img {
            display: inline-block;
            width: 16px;
            height: 11px;
            margin: 0 $offset-xs;
          }
        }
      }
    }
  }
  .slick-slide {
    margin: 0 1rem !important;
  }
  .slick-track {
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
  &__image {
    height: 200px;
    @include bg_set();
  }
}

/*---------------------------------------*\
   Contact us
\*---------------------------------------*/

.section-contact {
  padding: $offset-md 0 $offset-xl;
  > .container {
    > .row {
      @media(max-width: $screen_desktop) {
        flex-direction: column-reverse;
      }
    }
  }
  &__map {
    img {
      max-width: 100%;
      box-shadow: 31px 8px 60px 10px rgba(17, 17, 17, 0.05);
      position: relative;
      right: 2rem;
      border-radius: 0 20px 20px 0;
    }
  }
  &__info {
    margin: $offset-md;
    h1 {
      margin-bottom: $offset-lg;
    }
    h2 {
      @include fh();
      @include fs(18);
      color: $dark;
    }
  }
}

.section_single {
  padding: $offset-xl 0;
  &__slider {
    img {
      max-width: 400px;
      margin: auto;
      display: block!important;
    }
  }
  h2 {
    color: $dark;
    @include fs(22);
    @include fh();
    margin-bottom: 2rem;
    @media(max-width: $screen_mobile_max){
      margin: 2rem 0;
    }
  }
  span {
    display: block;
    @include fs(14);
    margin-bottom: 1rem;
    img {
      max-width: 100%;
      display: inline-block;
      margin-right: 5px;
      position: relative;
      bottom: 5px;
    }
  }
  p {
    @include fs(16);
    @include lh(18);
  }
  .slick-dots {
    bottom: -40px
  }
}

.section_banner {
   &__bg {
     @include bg_set();
     height: 100%;
     height: 100vh;
   }
  &__in {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12rem 0;
  }
}


.header {
  position: absolute;
  z-index: 100;
  background: $black;
  @include transition();
  width: 100%;
  float: left;
  &__row {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    @media(min-width: $screen_desktop) {
      flex-flow: row nowrap;
    }
  }
  &__logo {
    width: auto;
    position: relative;
    > a {
      padding: 3rem;
      text-align: center;
      position: absolute;
      box-shadow: 0 0 25px 4px rgba(17, 17, 17, 0.08);
      border-radius: 0 0 5px 5px;
      background-color: $white;
      @media(max-width: $screen_mobile_max) {
        padding: 3rem 2rem;
      }
    }
    img {
      @include img-fluid();
      max-width: 86px;
    }
  }
  #navigation {
    @include fs(15);
    display: none;
    width: 100%;
    text-align: center;
    @media(max-width: $screen_desktop) {
      padding-bottom: 2rem;
    }
    a {
      text-decoration: none;
    }
    ul {
      padding: 2rem 0;
      li {
        display: block !important;
        opacity: 0;
        > a {
          display: block;
          text-transform: uppercase;
          width: 100%;
          padding: $offset $offset $offset 0;
          opacity: 1;
          color: $primary;
          @include transition();
          &:hover{
          color: $white;
          };
        }
        &:first-child {
          > a {
            @media(min-width: $screen_desktop) {
              color: $white;
              border-bottom: 2px solid $white;
            }
          }
        }
      }
    }
    body.screen_md &, body.screen_lg & {
      width: auto;
      display: block !important;
      ul {
        display: inline-block;
        margin: 0;
        padding: 0;
        li {
          opacity: 1 !important;
          display: inline-block !important;
          width: auto;
          a {
            padding: 2rem 1rem;
            @media(min-width: 1250px) {
              padding: 2rem 1.5rem;
            }
          }
          &.active {
            color: $white;
          }
        }
      }
    }
  }

  #toggle {
    cursor: pointer;
    position: relative;
    outline: none;
    padding: 2rem;
    @media(max-width: $screen_desktop) {
      text-align: right;
    }
    body.screen_md &, body.screen_lg & {
      display: none !important;
    }
  }
  .menu_active & {
    background-color: rgba($white, 0.9);
  }
  .menu_active.screen_md &, .menu_active.screen_lg & {
    background: $dark !important;
  }
}

/*---------------------------------------*\
   Default Styles
\*---------------------------------------*/

html,
body {
  width: 100%;
  height: 100%;
}

html {
  font-size: $bs*1px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  overflow-x: hidden;
  @include fb();
  font-weight: 400;
  color: $text;
  overflow: auto;
  font-size: 14px;
  @media screen and (min-width: map-get($grid-breakpoints, sm)) {
    font-size: 15px;
  }
  @media screen and (min-width: map-get($grid-breakpoints, md)) {
    font-size: 16px;
  }
  &::before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 99999999;
    background-color: $white;
    transition: all 0.8s linear;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  &.loaded {
    backface-visibility: hidden;
    &::before {
      opacity: 0;
      z-index: -1;
    }
  }
}
body#tinymce::before {
  display: none !important;
}
.wrap {
  overflow-x: hidden;
}

.wrap,
.footer {
  @include clearfix();
}

a {
  color: inherit;
  text-decoration: none;
  @include transition();
  &:hover,
  &:active,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}

p {
  display: inline-block;
  width: 100%;
  margin: 0 0 $offset 0;
}

nav {
  ul {
    @include ul_reset();
  }
}

section {
  header {
    .divider {
      width: 117px;
      height: 3px;
      background-color: $primary;
      margin-bottom: $offset;
    }
    h1 {
      color: $dark;
      @include fh();
      @include fs(30);
      padding-bottom: $offset;
    }
  }
}

// Tables
.material-box {
  @include material-box();
  padding: $offset $offset-md;
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  background-color: $primary;
}

.responsive_states {
  visibility: hidden;
  overflow: hidden;
  height: 0;
}

/*---------------------------------------*\
   Responsive visibility
\*---------------------------------------*/
@mixin responsive-visibility(){
  display: block !important;
}
@mixin responsive-invisibility(){
  display: none !important;
}

.visible-xxs,
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  @include responsive-invisibility();
}
.visible-xxs {
  @media (max-width: $screen_mobile_max) {
    @include responsive-visibility();
  }
}
.visible-xs {
  @media (min-width: $screen_mobile) and (max-width: $screen_tablet_max) {
    @include responsive-visibility();
  }
}
.visible-sm {
  @media (min-width: $screen_tablet) and (max-width: $screen_desktop_max) {
    @include responsive-visibility();
  }
}
.visible-md {
  @media (min-width: $screen_desktop) and (max-width: $screen_large_max) {
    @include responsive-visibility();
  }
}
.visible-lg {
  @media (min-width: $screen_large) {
    @include responsive-visibility();
  }
}

.hidden-xxs {
  @media (max-width: $screen_mobile_max) {
    @include responsive-invisibility();
  }
}
.hidden-xs {
  @media (min-width: $screen_mobile) and (max-width: $screen_tablet_max) {
    @include responsive-invisibility();
  }
}
.hidden-sm {
  @media (min-width: $screen_tablet) and (max-width: $screen_desktop_max) {
    @include responsive-invisibility();
  }
}
.hidden-md {
  @media (min-width: $screen_desktop) and (max-width: $screen_large_max) {
    @include responsive-invisibility();
  }
}
.hidden-lg {
  @media (min-width: $screen_large) {
    @include responsive-invisibility();
  }
}
.visible-print {
  @include responsive-invisibility();
  @media print {
    @include responsive-visibility();
  }
}
.hidden-print {
  @media print {
    @include responsive-invisibility();
  }
}

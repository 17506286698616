/*---------------------------------------*\
   Buttons
\*---------------------------------------*/
.btn,
a.btn {
  @include fs(15);
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  background-color: $primary;
  border: none;
  color: $white;
  padding: ( 12 / $bs * 1rem) (20 / $bs * 1rem);
  display: inline-block;
  letter-spacing: 1px;
  border-radius: 4px;
  text-decoration: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  vertical-align: middle;
  @include transition();
  &:hover {
    background: lighten($primary, 10%);
    border-color: transparent;
  }
  &:active,
  &:focus {
    background: darken($primary, 10%);
    color: $white;
  }
  &.btn-gray {
    background: #a79f96;
    color: $white;
    &:hover {
      background: lighten(#a79f96, 10%);
    }
    &:active,
    &:focus {
      background: darken(#a79f96, 10%);
      color: $white;
    }
  }
  &.btn-secondary {
    border-color: transparent;
    background-image: none;
    background-color: $secondary;
    color: $white;
    &:hover {
      background: lighten($secondary, 6%);
    }
    &:active,
    &:focus {
      background: darken($secondary, 10%);
      color: $white;
    }
  }
  &.btn-white {
    border-color: transparent;
    background-image: none;
    background-color: $white;
    color: $primary;
    &:hover {
      background: darken($white, 6%);
    }
    &:active,
    &:focus {
      background: darken($white, 10%);
      color: $primary;
    }
  }
  &.btn-grad {
    border-color: transparent;
    background-image: none;
    background-color: $secondary;
    padding: ( 12 / $bs * 1rem) (30 / $bs * 1rem);
    background-image: linear-gradient(-205deg, #680b2a 0%, #680b2a 10%, #811d3e 50%, #680b2a 90%, #680b2a 100%);
    color: $white;
    &:hover {
      background: lighten($secondary, 6%);
    }
    &:active,
    &:focus {
      background: darken($secondary, 10%);
      color: $white;
    }
  }
  &.btn-mega {
    padding: ( 24 / $bs * 1rem) (55 / $bs * 1rem);
    @include fs(22);

  }
  &.btn-big {
    padding: ( 20 / $bs * 1rem) (45 / $bs * 1rem);
  }
  &.btn-small {
    padding: ( 6 / $bs * 1rem) (25 / $bs * 1rem);
    @include fs(14);
    font-weight: 300;
  }
}
